header{
	background-color: #000ae3;
	height: 90px;
	@include media-breakpoint-down(md) {
		height: 70px;
	}
	.interior &{
		//background-color: #2a2c2c;
	}
	.planes.interior &{
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 10;
	}
	.centros.interior &{
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 10;
	}
	.logo{
		margin-top: -68px;
		margin-left: -71px;
		position: absolute;
		@include media-breakpoint-down(md) {
			margin-top: -57px;
			margin-left: -60px;
			width: 120px;
		}
	}

	.menu{
		padding:0;
		text-align: center;
		li{
			margin-left: 10px;
			list-style:none;
			display: inline-block;
			@include fontSize($body-size-s);
			a{
				color:white; // #4c4b4b;
				&:hover{
					@extend .fuente-title;
					color: $orange; //$negro;
					text-decoration: underline;
				}
			}
			&.active{
				@extend .fuente-title;
				a{

					text-decoration: none;
					color:$rosa;
				}
			}
		}
	}

}
