html{
	font-size:$size-rem;
	@include media-breakpoint-down(xs) {
		font-size: 14px;
	}
	@media (max-height: 900px) {
		font-size: 14px;
	}
	body{
		@extend .fuente-body;
		@include fontSize($body-size-m);
		color: #2a2c2c;
		line-height: 1.2em;
		@include media-breakpoint-down(md) {
			overflow-x: hidden;
		}
		.container{
			width: 100%!important;
			max-width: 1220px;
			@media (max-height: 900px) {
				max-width: 1024px;
			}
			&.container-medium{
				max-width: 1380px;
			}
		}
		&.hidescroll{
			overflow-y: hidden;
			height: 100vh;
		}
		#wrapper{
			@include media-breakpoint-down(sm) {
			overflow-x:hidden;
			}
		}
	}
}

#section-home{
	background-image: url('../images/kv_bright.png'); // home-bg.jpg');
	background-size: cover;
	background-position: center 17%;
	height: calc(100vh - 450px);
	position:relative;
	min-height: 600px;
	@media (max-height: 1000px) {
		height: calc(55vh);
		min-height: auto;
	}
	@include media-breakpoint-down(md) {
		padding-top: 50px;
		min-height: auto;
	}
	.btn-primary{
		width: calc(100% - 44px);
		margin-right: 45px;
		margin-top: 15px;
		background: linear-gradient(267.63deg,#e35305 .65%,#fa9f1b);
		&:hover,&.active{
			background-color: #000ae3 !important;
		}
	}



}
.carrusel-partners{
	background-color: #2a2c2c;
	h2{
		color:$rosa;
		@extend .fuente-title;
		@include fontSize(22px);
		font-weight: bold;
	}

	.contenidoSlide{
		height: 16vw;
		@include media-breakpoint-down(md) {
			height: 56vw;
		}
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		.bgimage{
			background-size: cover;
			filter: grayscale(100%);
			@include transition(all 0.4s ease-out);
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0;
		}
		.titulo{
			@extend .fuente-title;
			@include fontSize(18px);
			color:$color-primary;
			background-color: rgba($rosa,0.8);
			height: 80px;
			width: 100%;
			color:$blanco;
			display: block;
			border-left: 1px solid rgba($blanco,0.5);
			position: relative;
			z-index: 2;
			@include transition(all 0.4s ease-out);
			padding:0 15px;
		}
		&:hover{
			.bgimage{
				filter: grayscale(0%);
			}
			.titulo{
				height:100px;
				text-decoration: none;
			}
		}

	}
	.swiper-button-next{
		top: 40%;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23ffffff'/%3E%3C/svg%3E");
	}
	.swiper-button-prev{
		top: 40%;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23ffffff'/%3E%3C/svg%3E");
	}
}
.section-logos-redes{
	background-color: #000ae3;
	.iconos-redes{
		padding:0;
		margin:12px 0 0; 
		@media (max-height: 1000px) {
			/*margin:20px 0 0;*/
		}
		li{
			margin:0 8px;
			display: inline;
			@include media-breakpoint-down(md) {
				margin:2px 8px;
			}
			img{
				filter: invert(1);
				height: 60px!important;
				@media (max-height: 1000px) {
					/*height: 50px;*/
				}
				@include media-breakpoint-down(md) {
					height: 50px!important;
				}
			}
		}
	}
}
.filtro{
	position:relative;
	.titulofiltro{
		cursor:pointer;
		padding-bottom: 10px;
	}

	.bocadillo{
		position:absolute;
		top:30px;
		left: 44%;
		width: 700px;
		max-width: 100vw;
		transform: translateX(-50%);
		background-color: $blanco;
		border:solid 1px $negro;
		z-index: 3;
		transition: all 0.5s;
		opacity:0;
		@include media-breakpoint-down(md) {
			left: -8px;
			transform: translateX(0);
			width: calc(100vw - 15px);
			overflow-y: scroll;
			max-height: calc(100vh - 270px);
			overflow-x: hidden;
		}
		.row-checks{
			@include media-breakpoint-down(md) {
				padding-left: 7px!important;
				padding-right: 7px!important;
			}
			
		}
		.flecha{
			display: block;
			position:absolute;
			top: -6px;
			left: 50%;
			transform: rotate(45deg);
			background-color: $blanco;
			border-top: solid 1px $negro;
			border-left: solid 1px $negro;
			width: 10px;
			height: 10px;
			@include media-breakpoint-down(md) {
				left: 25%;
			}
		}
		&.bocadillo-inout{
			width: 200px;
		}
		&.bocadillo-provincias{
			@include media-breakpoint-down(md) {
				/*transform: translateX(calc(-50% - 15px));
				.flecha{
					left: 75%!important;
				}*/
			}
		}
		&.bocadillo-categorias	{
			@include media-breakpoint-down(md) {
				/*transform: translateX(calc(-32% - 15px));
				.flecha{
					left: 55%!important;
				}*/
			}
			/*.cajaCheck{
				label{
					width:50px;
				}
			}JC*/
		}
		.cajaCheck input[type="checkbox"]:checked,
		.cajaCheck input[type="checkbox"]:not(:checked) {
		  position: absolute;
		  left: -9999px;
		  opacity:0;
		  text-transform: uppercase;
		}

		.cajaCheck input[type="checkbox"]:checked + label,
		.cajaCheck input[type="checkbox"]:not(:checked) + label
		{
		  position: relative;
		  padding-left: 28px;
		  cursor: pointer;
		  line-height: 20px;
		  display: inline-block;
		  color: $negro;
		  font-size: 16px;
		  text-transform: uppercase;
		  @include media-breakpoint-down(md) {
			font-size: 14px;
			  
		  }
		  @include media-breakpoint-down(xs) {
			font-size: 13px;
			font-family: 'Gotham Light';
			line-height: 16px;
			padding-left: 23px;
			  
		  }
		}
		.cajaCheck input[type="checkbox"]:checked + label:before,
		.cajaCheck input[type="checkbox"]:not(:checked) + label:before {
		  content: '';
		  position: absolute;
		  left: 0;
		  top: 0;
		  width: 18px;
		  height: 18px;
		  //border: 2px solid $negro;
		  border-radius: 100%;
		  background-color: grey;
		}
		.cajaCheck input[type="checkbox"]:checked + label:after,
		.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
		  content: '\f00c';
		  font-family: FontAwesome;
		  width: 18px;
		  height: 18px;
		  position: absolute;
		  top: 0px;
		  left: 0px;
		  border-radius: 100%;
		  -webkit-transition: all 0.2s ease;
		  transition: all 0.2s ease;
		  color:white;
		  font-size: 11px;
		  line-height: 18px;
		  text-align: center;
		}
		.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
		  // opacity: 0;
		  // -webkit-transform: scale(0);
		  // transform: scale(0);
		  color:white;
			background: rgba(206,206,206,1);
			background: -moz-linear-gradient(-45deg, rgba(206,206,206,1) 0%, rgba(206,206,206,1) 33%, rgba(255,255,255,1) 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(206,206,206,1)), color-stop(33%, rgba(206,206,206,1)), color-stop(100%, rgba(255,255,255,1)));
			background: -webkit-linear-gradient(-45deg, rgba(206,206,206,1) 0%, rgba(206,206,206,1) 33%, rgba(255,255,255,1) 100%);
			background: -o-linear-gradient(-45deg, rgba(206,206,206,1) 0%, rgba(206,206,206,1) 33%, rgba(255,255,255,1) 100%);
			background: -ms-linear-gradient(-45deg, rgba(206,206,206,1) 0%, rgba(206,206,206,1) 33%, rgba(255,255,255,1) 100%);
			background: linear-gradient(135deg, rgba(206,206,206,1) 0%, rgba(206,206,206,1) 33%, rgba(255,255,255,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cecece', endColorstr='#ffffff', GradientType=1 );		  
		}
		.cajaCheck input[type="checkbox"]:checked + label:after {
		  // opacity: 1;
		  // -webkit-transform: scale(1);
		  // transform: scale(1);
		  background-color: #22a45f;
		  color:white;
		}


	}

	@include media-breakpoint-up(md) {

		&:hover{

			.bocadillo{
				visibility: visible !important;
				opacity:1;
				display: block !important;
			}
		}	

	}


	@include media-breakpoint-down(sm) {

		&.activo{

			.bocadillo{
				visibility: visible !important;
				opacity:1 !important;
				display: block !important;
			}
		}	

	}

}

#section-planes{
	@include fontSize($body-size-m);
	min-height: calc(100vh - 260px);
	.aviso-consumo{
		@include media-breakpoint-down(md) {
			padding: 0 15px;
		}
	}
	.info-usuario{
		padding-left: 10vw;
		padding-right: 10vw;
		border:2px solid $negro;
		margin-top:45px;
		margin-bottom:45px;
		@include media-breakpoint-down(md) {
			margin-top:0;
			margin-bottom:30px;
		}
		@media (max-height: 900px) {
			padding-bottom:15px!important;
			padding-top:15px!important;

		}
		p{
			font-family: 'Gotham Light';
			color:$negro;
			strong{
				@include fontSize(22px);
				font-family: 'Gotham Book';
			}
		}
	}
	h1{
		@extend .title-l;
		color:$negro;
		line-height: 1em;
		@include media-breakpoint-down(md) {
			@include fontSize(26px);
		}
	}
	h2{
		@extend .title-m;
		font-family: 'Gotham Book';
		color:$negro;
		line-height: 1em;
		margin-top: 60px;
	}
	.tab-filtro{
		@include fontSize(28px);
		font-family: 'Gotham Light';
		height: 80px;
		overflow: hidden;
		cursor: pointer;
		@include media-breakpoint-down(md) {
			@include fontSize(20px);
		}
		@include media-breakpoint-down(xs) {
			@include fontSize(18px);
		}
		a{
			color:$blanco;
			white-space: nowrap;
			z-index: 10;
			padding-bottom: 4px;
			position: relative;
			@include media-breakpoint-down(md) {
				white-space: normal;
				transform: scaleX(0.9);
				letter-spacing: -1px;
			}
			&:hover{
				text-decoration: none;
			}
			&:before{
				content:" ";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0%;
				border-bottom:2px solid $blanco;
				@include transition(all 0.7s ease-out);

			}
		}
		strong{
			font-family: 'Gotham Bold';
		}
		&.tab-inhome{
			background-color: $rosa;
			/*@include transform(scaleX(104%));*/
			@include transform(scaleX(1.04));
			@include media-breakpoint-down(md) {
				@include transform(scaleX(1.3));
			}
			&:after{
				content:" ";
				position: absolute;
				background-color: rgba($rosa-hover,0.5);
				width: 0%;
				top: 0;
				height: 100%;
				left: -30px;
				@include transition(all 0.5s ease-out);
				transform: skew(-10deg);
			}
			&:hover{
				&:after{
					width: 15%;
				}
			}
		}
		&.tab-all{
			display: contents;
			a{
				background-color: $gris;
				padding: 0 20px;
				border-left:3px solid $blanco;
				border-right:3px solid $blanco;
				transform: skewX(-10deg);
				z-index: 10;
				overflow-x: hidden;

				span{
					transform: skewX(10deg);
					z-index: 10;
					padding:3px 0;
					margin:auto;
					&:before{
						content:" ";
						position: absolute;
						left: 0;
						bottom: 0;
						width: 0%;
						border-bottom:2px solid $blanco;
						@include transition(all 0.7s ease-out);
		
					}
		
				}
				&:after{
					content:" ";
					position: absolute;
					background-color: rgba($gris-hover,0.5);
					width: 0%;
					top: 0;
					height: 100%;
					left: 00px;
					@include transition(all 0.5s ease-out);
				}
				&:hover{
					&:after{
						width: 25%;
					}
				}
			}
		}
		&.tab-outhome{
			background-color: $azul;
			/*@include transform(scaleX(104%));*/
			@include transform(scaleX(1.04));
			@include media-breakpoint-down(md) {
				@include transform(scaleX(1.3));
			}
			&:before{
				content:" ";
				position: absolute;
				background-color: rgba($azul-hover,0.5);
				width: 0%;
				top: 0;
				height: 100%;
				left: -30px;
				@include transition(all 0.5s ease-out);
				transform: skew(-10deg);
			}
			&:hover{
				&:before{
					width: 15%;
				}
			}
		}
		&.selected{
			&.tab-inhome{
				&:after{
					background-color: $rosa-hover;
					width: 110%;
				}
				a{
					&:before{
						width: 100%;
					}
				}
			}
			&.tab-all{
				a{
					&:after{
						background-color: $gris-hover;
						width: 125%;
					}
					span{
						&:before{
							width: 100%;
						}
					}
				}
			}
			&.tab-outhome{
				&:before{
					background-color: $azul-hover;
					width: 110%;
				}
				a{
					&:before{
						width: 100%;
					}
				}
			}
		}

		@media all and (-ms-high-contrast:none)
		{

			&.tab-all a.js_tipo-red { height: 100%; } /* IE10 */
			*::-ms-backdrop, &.tab-all a.js_tipo-red  {  height: 100% } /* IE11 */
			&.tab-inhome,&.tab-outhome{
				@include transform(scaleX(1.14));
			}
			*::-ms-backdrop, &.tab-inhome,&.tab-outhome{
				@include transform(scaleX(1.14));
			}
		}

	}

	.item{
		cursor: pointer;
		height: 300px;
		width: 215px;
		position: relative;
		margin-bottom: 15px;
		@include transition(all 0.4s ease);
		transition-delay: 0.2s;
		@include media-breakpoint-down(md) {
			height: 63vw;
			width: 48%;
		}
		.imagen{
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			height: calc(300px - 80px);
			@include transition(all 0.5s ease);
			@include media-breakpoint-down(md) {
				height: calc(63vw - 80px);
			}
		}
		.capa{
			position: absolute;
			left:.25rem;
			bottom: 0;
			width: calc(100% - 0.5rem);
			height: 80px;
			@include transition(height 0.5s ease);
			.icono-check{
				overflow: hidden;
				height: 0px;
				@include transition(height 0.4s ease);
				#svg-check{
					width: 35px;
					height: 35px;
					circle{
						stroke-dasharray: 185;
						stroke-dashoffset: 185;
						@include transition(all 0.4s ease-out);
					}
					polyline{
						stroke-dasharray: 70;
						stroke-dashoffset: 70;
						@include transition(all 0.4s ease-out);
					}
				}
			}
			img{
				@include media-breakpoint-down(xs) {
					height: 47px!important;
				}
				
			}
		}
		&:hover{
			// filter: brightness(70%);
			.capa{
				height: 100px;
			}
		}
		&.js-seleccionada{
			// filter: brightness(33%);
			&:hover{
				// filter: brightness(33%);
			}
			.capa{
				height: 120px;
				.icono-check{
					height:35px;
					#svg-check{
						circle{
							transition-delay: 0.3s;
							stroke-dashoffset: 0;
						}
						polyline{
							transition-delay: 0.5s;
							stroke-dashoffset: 0;
						}
					}
				}
			}
			.imagen{
				filter: brightness(53%);
			}
		}
		&.js-redinactiva{
			transform: scale(0);
			width: 0;
			padding: 0!important;
		}
	}
	.grid-items{
		.item{
			position:relative;
			cursor: pointer;
			transition: all 0.4s ease-out;
			@media (max-height: 900px) and (orientation: landscape)  {
				height: 270px;
				overflow:hidden;
			}
			.fondo-dsk{
				@media (max-height: 900px) and (orientation: landscape)  {
				    position: relative;
				    margin-top: -20px;
				}
			}
			h2{
				color:$negro;
				text-transform: uppercase;
			}

			.capa{
				transition: background-color 0.5s ease;
				background-color: transparentize($negro, 0.5);
				position:absolute;
				top: 0px;
				left: 0px;
				.check{
					position: absolute;
					bottom: 10%;
					left: 50%;
					margin-left: -25px;
					border: 3px solid
					white;
					border-radius: 50%;
					width: 50px;
					height: 50px;
					text-align: center;
					line-height: 43px;
					opacity: 0;
					transition:all 0.5s ease;
					@include media-breakpoint-down(md) {
						width: 40px;
						height: 40px;
						margin-left: -20px;
						line-height: 33px;
						img{
							width: 20px;
							height: 20px;
						}
					}
					@include media-breakpoint-down(xs) {
						width: 32px;
						height: 32px;
						margin-left: -16px;
						line-height: 26px;
						img{
							width: 16px;
							height: 16px;
						}
					}
				}
			}

		}

		.item-bienestar{
			max-width: 244px;
			.item{
				.capa{

					@include media-breakpoint-up(md) {

					&:hover{
						background-color: transparentize($verde-oscuro, 0);
					}

					}
				}
				.categoria-activa{
					background-color: transparentize($verde-oscuro, 0);
					.check{
						opacity: 1;
					}
				}
			}
		}

		.item-deporte{
			max-width: 244px;
			.item{
				.capa{

					@include media-breakpoint-up(md) {

					&:hover{
						background-color: transparentize($verde-claro, 0);
					}

					}

				}
				.categoria-activa{
						background-color: transparentize($verde-claro, 0);
					.check{
						opacity: 1;
					}
				}
			}
		}

		.item-planes{
			max-width: 244px;
			.item{
				.capa{
					
					@include media-breakpoint-up(md) {

					&:hover{
						background-color: transparentize($naranja, 0);
					}

					}

				}
				.categoria-activa{
						background-color: transparentize($naranja, 0);
					.check{
						opacity: 1;
					}
				}
			}
		}

		.item-kids{
			max-width: 244px;
			.item{
				.capa{

					@include media-breakpoint-up(md) {

					&:hover{
						background-color: transparentize($rosa, 0);
					}

					}

				}
				.categoria-activa{
						background-color: transparentize($rosa, 0);
					.check{
						opacity: 1;
					}
				}
			}
		}

		.item-talento{
			max-width: 244px;
			.item{
				.capa{

					@include media-breakpoint-up(md) {

						&:hover{
							background-color: transparentize($morado, 0);
						}

					}

				}
				.categoria-activa{
						background-color: transparentize($morado, 0);
					.check{
						opacity: 1;
					}
				}
			}
		}								

	}
}

#section-plan-aviso{
	@include fontSize($body-size-m);

	.aviso-consumo{
		@include media-breakpoint-down(md) {
			padding: 0 15px;
		}
	}
	.info-usuario{
		padding-left: 10vw;
		padding-right: 10vw;
		border:2px solid $negro;
		margin-top:45px;
		margin-bottom:45px;
		@include media-breakpoint-down(md) {
			margin-top:0;
			margin-bottom:30px;
		}
		@media (max-height: 900px) {
			padding-bottom:15px!important;
			padding-top:15px!important;

		}
		p{
			font-family: 'Gotham Light';
			color:$negro;
			strong{
				@include fontSize(22px);
				font-family: 'Gotham Book';
			}
		}
	}
}

.planes.interior{
	padding-top: 85px;
}

#section-plan{
	@include fontSize($body-size-m);
	min-height: calc(100vh - 738px);
	
	.seleccion{
		// margin-top: 10px;
		// left: 0;
		// padding-top: 0px;
		background-color: white;
		z-index: 10;
		position: fixed;
		top: 65px;
		margin-top: 25px!important;
		left: auto;
		right: auto;
		padding-top: 5px;
		margin-left: -15px;
		width: 100%;
		max-width: 1220px;
		&.con-aviso{
			top:235px;
		}

		@include media-breakpoint-down(md) {
			top: 45px;
			left: 15px;
			padding-top: 10px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 25px;
			position:fixed;
		}
		@media (max-height: 900px) {
			max-width: 1024px;
		}
	

		ul{
			padding: 0;
			margin:0;
			@include media-breakpoint-down(md) {
				margin-bottom: 15px;
			}
			li{
				padding: 10px 15px;
				margin:0;
				list-style: none;
				display: inline-block;
				@include media-breakpoint-down(md) {
					padding: 5px 0px;
					display: block;
				}
				.titulofiltro{
					@include fontSize(18px);
					padding-left: 22px;
				}
				.badge{
					background-color:$negro;
					@include border-radius(50%);
					width: 22px;
					height: 22px;
					color: $blanco;
					font-size: 14px;
					line-height: 24px;
					font-family: "Gotham Bold";
					@include transform(scale(0%));
					@include transition (all 0.5s ease);
					transform-origin: 50% 50%;
					text-align: center;
					padding:0;
					letter-spacing: -1px;
					vertical-align: middle;
					&.activo{
						@include transform(scale(100%));
					}			
				}
			}
		}
	}

	.grid-items{
		// margin-top: 40px;
		margin-top: 253px;
		transition: all 0.5s ease-out;

		@include media-breakpoint-down(md) {
			margin-top: 180px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 150px;
		}


		.item{
			position:relative;
			// height: 286px;
			color:$negro;
			@include media-breakpoint-down(md) {
				height: auto;
			}

			.imagen{
				// object-fit: cover;
				@include transform(translateX(-50%));
				left: 50%;
				height: unset!important;
			}
			.titulo-plan{
				position: relative;
				/*padding-left: 20px;
				&:before{
					content:" ";
					background-image: url(../images/stripes.svg);
					background-repeat: no-repeat;
					width: 30px;
					height: 26px;
					position: absolute;
					top: 0;
					left: 0;
					background-size: contain;
					@include media-breakpoint-down(md) {
						top:-3px;
					}
				}*/
			}
			.titulo-plan,.texto-plan{
				color:$negro;
				line-height: 1em;
				p{
					line-height: 1.2em;
				}
			}

			h2{
				color:$negro;
				text-transform: uppercase;
			}
		}		
		.label-in{
			color: $orange;
			padding:3px 20px;
			border: 2px solid $orange;
			font-family: Gotham Bold;
			font-size: 0.7em;
			font-weight: bolder;
			img{
				margin-left: 6px;
			}
		}	
		.label-out{			
			color: $blue;
			padding:3px 20px;
			border: 2px solid $blue;
			font-family: Gotham Bold;
			font-size: 0.7em;
			font-weight: bolder;
			img{
				margin-left: 6px;
			}
		}					

		&.only-in{
			.label-in{
				display: inline;
			}
			.label-out{
				display: none;
			}
		}
		&.only-out{
			.label-in{
				display: none;
			}
			.label-out{
				display: inline;
			}
		}
	}
	#alert-filtro{
		padding-right: 30px;
		padding-left: 30px;
	}
}
body{
	&.scroll{
		#section-plan{
			.seleccion{
				position: fixed;
				top: 65px;
				margin-top: 25px!important;
				left: auto;
				right: auto;
				padding-top: 5px;
				margin-left: -15px;
				width: 100%;
				max-width: 1220px;
				box-shadow: 0px 14px 14px -14px rgba(0,0,0,.3);
				@include media-breakpoint-down(sm) {
					top: 45px;
				}
				@media (max-height: 900px) {
					max-width: 1024px;
				}
			
			}
			.grid-items{
				// margin-top:222px; // en JS
			}
		}		
	}
}
#section-centros{
	@include fontSize($body-size-m);
	min-height: calc(100vh - 260px);
	.label-in{
		color: $orange;
		padding:3px 15px;
		border: 2px solid $orange;
		font-family: Gotham Bold;
		font-size: 0.7em;
		font-weight: bolder;
		img{
			margin-left: 6px;
		}
	}	
	.label-out{			
		color: $blue;
		padding:3px 15px;
		border: 2px solid $blue;
		font-family: Gotham Bold;
		font-size: 0.7em;
		font-weight: bolder;
		img{
			margin-left: 6px;
		}
	}
	.distance{
		@include fontSize(16px);
		color: $orange;
		font-family: Gotham Bold;
		margin-bottom: 0;
		margin-top: 8px;
		position: relative;
		margin-left: 15px;
		transform: translateY(2px);
		&:before{
			content: " ";
			background-image: url(../images/stripe-black.svg);
			background-repeat: no-repeat;
			background-size: contain;
			width: 20px;
			height: 21px;
			position: absolute;
			top: -2px;
			left: -10px;
			
		}
	}
	.fa-arrow-left{
		color: #000;
		@include fontSize(30px);
	}
	.aviso-consumo{
		@include media-breakpoint-down(md) {
			padding: 0 15px;
		}
	}
	.info-usuario{
		padding-left: 10vw;
		padding-right: 10vw;
		border:2px solid $negro;
		margin-top:45px;
		margin-bottom:45px;
		@include media-breakpoint-down(md) {
			margin-top:0;
			margin-bottom:30px;
		}
		@media (max-height: 900px) {
			padding-bottom:15px!important;
			padding-top:15px!important;

		}
		p{
			font-family: 'Gotham Light';
			color:$negro;
			strong{
				@include fontSize(22px);
				font-family: 'Gotham Book';
			}
		}
	}
	.encabezado{
		height: 450px;
		background-color: $negro;
		background-size: cover;
		background-position: center center;
		@include media-breakpoint-down(md) {
			height: 300px;
		}
		@include media-breakpoint-down(sm) {
			height: 200px;
			margin-top:70px;
		}

		.contendor-titulo{
			background-color: rgba($blanco,.40);
			padding: 25px 0 20px;
			.titulo{
				@include fontSize($titulo-size-xxl);
				color:$negro;
				line-height: 1em;
				@extend .fuente-title;
				//padding-left: 30px;
				@include media-breakpoint-down(md) {
					@include fontSize(30px);
					//padding-left: 22px;
				}
				/*&:before{
					content: " ";
					background-image: url(../images/stripes.svg);
					background-repeat: no-repeat;
					width: 60px;
					height: 44px;
					position: absolute;
					top: 5px;
					left: 15px;
					background-size: contain;
					@include media-breakpoint-down(md) {
						top:-2px;
						height: 26px;
					}
				}*/
			}
		}
		&.fixed{
			position: fixed;
			top: -275px;
			width: 100%;
			z-index: 9;
			.titulo{
				padding-left: 20px;
				@include fontSize(30px);

				&:before{
					height: 23px;
					top: 0px;
				}
			}
		}

	}
	.volver{
		&.fixed{
			position: fixed;
			z-index: 10;
			top: 192px;
			@include media-breakpoint-down(md) {
				top:85px;
			}
		}
	}
	.nav-tabs{
		border-bottom: none;
		display: block;
		background-color: $blanco;
		@include media-breakpoint-down(md) {
			margin-top: -23px;
		}
		a{
			// margin-left: 15px;
		}
		&.fixed{
			position: fixed;
			top: 175px;
			width: 100%;
			z-index: 9;
			max-width: 1220px;
			left: auto;
			right: auto;
			transform: translateX(10px);
			padding: 24px 24px 18px 0;
			-webkit-box-shadow: 0 8px 14px -11px rgba(0,0,0,.3);
			-moz-box-shadow: 0 8px 14px -11px rgba(0,0,0,.3);
			box-shadow: 0 8px 14px -11px rgba(0,0,0,.3);

			@include media-breakpoint-down(md) {
				max-width: 100%;
				width: 100%;
				margin-left: 0;
				top: 85px;
				padding: 24px 15px 18px 0;
			}
			@media (max-height: 900px) {
				max-width: 1024px;
			}



		}
		.chosen-container{
			min-width:225px!important;
			padding-right: 0 .25rem;
			@media (max-height: 900px) {
				min-width:190px!important;
			}
			.chosen-single{
				border-radius:0px!important;
				border:none;
				height:26px!important;
				background-color: #ebebeb;
				box-shadow: none;
				// margin-left:-5px!important;
				text-transform: uppercase;
				span{
					margin-right:unset;
					position:relative;
					text-align: left;
					font-family: Gotham Book;
					font-size: .9375rem;
					margin-top: 1px;
					padding-right:40px;
					&:after{
						background-image: url(../images/ico-select-down.svg);
						background-size:cover;
						width:6px;
						height:3px;
						content:" ";
						display: inline-flex;
					    position: absolute;
						right: 5px;
						top: 10px;
					}
				}
			}
			.chosen-drop{
				border-radius:0px;
				box-shadow:none;
				background-color:#ebebeb;
				border:none!important;
				.active-result.highlighted{
					background-color:#3f4148;
					background-image:none;
				}
			}
			.chosen-results li.active-result{
				text-transform: uppercase;
				text-align: left;
			}
		}
		.chosen-container-active.chosen-with-drop .chosen-single{
			border: none;
			background-image:unset;
		}
	}
	.borde{
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		background-color: black;
		height: 100%;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	.subcategoria{
		position: absolute;
		top: 0;
		right: 55px;
		writing-mode: tb-rl;
		font-size: 10rem;
		font-family: Gotham Bold;
		text-transform: uppercase;
		white-space: nowrap;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	#listado{
		color:$negro;
		.item{
			padding-top:10px;
			@include fontSize($body-size-s);
			.col-logo{

			}
			h2{
				color:$negro;
				/*padding-left: 17px;
				&:before{
					content:" ";
					background-image: url(../images/stripes.svg);
					background-repeat: no-repeat;
					background-size: contain;
					width: 20px;
					height: 21px;
					position: absolute;
					top: 2px;
					left: 15px;
				}*/
				@include media-breakpoint-down(md) {
					@include fontSize(25px);
				}

			}
			h3{
				color:$negro;
			}
			.cuerpo{
				line-height: 1.2em;
			}
			.btn-skew{
				margin-top: 0px;
				margin-bottom: 10px;
			}
			&.active{
				background-color:#F2F2F2;
			}
		}								
		&.fixed{
			margin-top:580px; /*440*/
			@include media-breakpoint-down(sm) {
				margin-top:280px;
			}
		}
		@media all and (-ms-high-contrast:none) {
			.item { display: block !important;} /* IE10 */
			*::-ms-backdrop, .item {display: block !important; } /* IE10 */
		}
	}

	#map{
		height: 80vh;
	}
}

#section-contacta{
	@include fontSize($body-size-m);

	.texto{
		
		line-height: 1.2em;

		ul{
			margin:0px;
			padding:0px;
			padding-left: 35px;

			li{
				list-style: none;

				&:before {
				  content: "-";
				  text-indent: -10px;
				  position:absolute;
				}
			}
		}
	}

	.form-group{

		position:relative;

		input{
			width: 100%;
			height: 48px;
			border:none;
			background-color: $bg-grey;
			border:solid 1px $bg-grey;
			text-align: left;
			@include media-breakpoint-down(md) {
				height: 45px;
			}
		}

		textarea{
			width: 100%;
			border:none;
			background-color: $bg-grey;
			border:solid 1px $bg-grey;
			text-align: left;
			height: 215px;
			padding: 15px;
		}
		
		&.has-error{

			input{
				border:solid 1px red;
			}

			textarea{
				border:solid 1px red !important;
			}
		}

	}

	a{
		@extend .fuente-title;
		color:$negro;
		text-decoration: underline;
	}

	.cajaCheck input[type="checkbox"]:checked,
	.cajaCheck input[type="checkbox"]:not(:checked) {
	  position: absolute;
	  left: -9999px;
	  opacity:0;
	}

	.cajaCheck input[type="checkbox"]:checked + label,
	.cajaCheck input[type="checkbox"]:not(:checked) + label
	{
	  position: relative;
	  padding-left: 28px;
	  cursor: pointer;
	  line-height: 20px;
	  display: inline-block;
	  color: $negro;
	}
	.cajaCheck input[type="checkbox"]:checked + label:before,
	.cajaCheck input[type="checkbox"]:not(:checked) + label:before {
	  content: '';
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 18px;
	  height: 18px;
	  border: 2px solid $negro;
	}
	.cajaCheck input[type="checkbox"]:checked + label:after,
	.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
	  content: '';
	  width: 18px;
	  height: 18px;
	  background: $negro;
	  position: absolute;
	  top: 0px;
	  left: 0px;
	  -webkit-transition: all 0.2s ease;
	  transition: all 0.2s ease;
	}
	.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
	  opacity: 0;
	  -webkit-transform: scale(0);
	  transform: scale(0);
	}
	.cajaCheck input[type="checkbox"]:checked + label:after {
	  opacity: 1;
	  -webkit-transform: scale(1);
	  transform: scale(1);
	}

	@include media-breakpoint-up(sm) {
		.chosen-select{
			position: absolute !important;
			top:-20px;
			left:-20px;
			width: 0px;
			height: 0px;
			opacity: 0 !important;
			display: block !important;
		}
	}

	@include media-breakpoint-down(sm) {
		.chosen-select{
			border:none;
			border-radius: 0px;
			height: 45px;
			background-color: $bg-grey;
		}
	}	

	.chosen-container{
		width: 100% !important;

		.chosen-single{

			border-radius: 0px;
			border: none;
			background-color: $bg-grey;
			height: 48px;
			line-height: 48px;
			box-shadow: none !important;
			font-family: 'Gotham Light';
			text-decoration: none;
			font-size: 18px;

			b{
				&:before{
					content: "\f0d7";
					font-family: FontAwesome;
					position:relative;
					left:-5px;
				}
			}

		}

		.chosen-drop{
			border-radius: 0px;
			border: none;
			background-color: $bg-grey;
			box-shadow: none !important;			
		}

		a{
			text-decoration: none;
		}

	}
	.alerta-gracias{
		display: none;
	}
	.gracias-contacto{
		h2{
			margin-top: 50px;
			margin-bottom: 20px;
			color:#000;
		}
	}

}

.contenedor-contacta {
	margin-top: 60px;
	@include media-breakpoint-down(md) {
		margin-top: 30px;
	}
	h1{
		// padding-left: 25px;
		color: $negro;
		@include media-breakpoint-down(md) {
			@include fontSize(36px);
		}
		// &:before{
		// 	content: " ";
		// 	background-image: url(../images/stripes.svg);
		// 	background-repeat: no-repeat;
		// 	width: 30px;
		// 	height: 28px;
		// 	position: absolute;
		// 	top: 5px;
		// 	left: 18px;
		// 	background-size: contain;
		// }
	}
	p,ul{
		color:$negro;
	}
	h2{
		color:$negro;
	}
	label {
		color:$negro;

	}
}


.gm-style .gm-style-iw-c{
	border-radius: 0;
	border: 2px solid black;
	padding-top: 25px;
	a{
		color: white;
		background-color: black;
		padding: 2px 15px 2px;
		float: right;
		margin-top: 10px;		
	}
}
.gm-style .gm-style-iw-t::after{
	background-color: white;
	border-left: 2px solid black;
	border-bottom: 2px solid black;
}


.cargador-planes{
	position:fixed;
	top:0;
	left:0;
	width: 100vw;
	height: 100vh;
	background-color:rgba(255,255,255,0.95);
	color:$negro;
	z-index: 20;
	&:after{
		content: " ";
		background-image: url(../images/spinner.svg);
		background-size: 22px;
		width: 22px;
		height: 22px;
		position: absolute;
		left: 50%;
		margin-left: -11px;
		top: 50%;
		margin-top: -11px;
		@include giroinfinito(1.5s);
	}
}
#iconos{
	background-color: #000ae3;
	.imgs-networks{
		@include media-breakpoint-down(sm) {
			padding-top:15px;
		}	
		img{
			filter: invert(1);
		}
	}
}

#section-partner{
	.cabecera{
		height: auto;
		min-height: 330px;
		background-repeat: no-repeat;
		background-position: center;
		background-color: $negro;
		background-size: cover;
	}
	.titular{
		background-color: rgba($blanco,.8);
		color:$negro;
		@extend .fuente-title;
		@include fontSize(24px);
		padding: 10px 0 10px 30px;
	}
	h3{
		color:$negro;
		@extend .fuente-title;
		@include fontSize(20px);
	}
	.text-descripcion{
		a{
			color:$negro;
			text-decoration: underline;
		}
	}
	.info-inferior{
		background-color: $txt-grey;
		margin-top: 30px;
		.iconos-redes{
			list-style: none;
			margin: 20px 0;
		}
	}
	.carrusel-testimonials{
		background-color: $bg-grey;
		margin-top: 30px;
		padding: 30px 0;
		.texto{
			@extend .fuente-title;
			@include fontSize(22px);
			font-style: italic;
		}
		.nombre{
			font-family: 'Gotham Light';
			@include fontSize(18px);
			
		}
		.swiper-button-next{
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%239d9c9c'/%3E%3C/svg%3E");
			@include transform(scale(0.5));
		}
		.swiper-button-prev{
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%239d9c9c'/%3E%3C/svg%3E");
			@include transform(scale(0.5));
		}
	}
}

.cont-search-address{
	.cont-address{
		.input-group{
			height: 45px;
			.input-group-prepend{
				position: relative;
				&:after{
					content:" ";
					border-right: 2px solid black;
					margin-top: 7px;
					position: absolute;
					right: 0;
					height: calc(100% - 14px);
				}
				.input-group-text{
					border: 2px solid black;
					border-right: none;
					border-radius: 0;
					background-color: $blanco;
				}
			}
			input{
				border: 2px solid black;
				border-left: none;
				padding-left: 15px;
				width: calc(100% - 46px);
				@include fontSize(16px);
				box-shadow: none!important;
			}
		}
	}
	.cont-slider{
		.label-title{
			@include fontSize(16px);
			color:$negro;
		}
		.label-slider{
			@include fontSize(16px);
			color:$rosa;
			font-family: 'Gotham Bold';;
			float: right;
		}
		#slider{
			background-color: #bdbdbd;
			height: 4px;
			transform: translateY(3px);
			.ui-slider-handle{
				border-radius: 50%;
				width: 18px;
				height: 18px;
				background-color: black;
				border: none;
				top: -8px;
			}
			.slider-start{
				width: 0;
				background-color: $azul;
				height: 4px;
				margin-top: -1px;
				border-radius: 3px;
			}
		}
		&.disabled{
			opacity: 0.35;
			pointer-events: none;
		}
	}
	.cont-btn{

	}
	.txt_resultados{
		background-color: #ebebeb;
		padding: 8px;
		span{
			display: inline-flex;
			padding-left: 15px;
			color:$negro;
		}
	}
}
#carga{
	position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.9);
    top: 0;
    left: 0;
    z-index: 10;
	img{
		@include giroinfinito(1.5s);
	}
}
#menu_filtros{
	.btn-terciary{
		&:hover,&.active{
			background:linear-gradient(267.63deg,#e35305 .65%,#fa9f1b 100%);
		}
	}
}