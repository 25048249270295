footer{
	padding:50px 0 25px;
	@include media-breakpoint-down(md) {
		padding:0px;
	}	
	@include media-breakpoint-down(sm) {
		padding-bottom: 20px;
		padding-top: 20px;
	}	
	background-color: #000ae3!important;
	/* height: 19vh; 
	max-height: 170px;*/
	margin-bottom: 0px;
	.menu{
		padding:0;
		text-align: center;
		position: relative;
		@media only screen and (max-width: 1270px) {
			margin-bottom: 45px;
		}
		@include media-breakpoint-down(md) {
			margin-bottom: 45px;
		}
		li{
			list-style: none;
			display: inline-block;
			@include fontSize($body-size-xs);
			@extend .fuente-title;
			a{
				text-transform: uppercase;
				color:#fff;
				&:hover{
					color:$txt-grey;
					text-decoration: none;
				}
			}
			&:after{
				content:"|";
				margin:0 5px;
			}
			&:last-child{
				@include media-breakpoint-down(lg) {
					display: block;
					margin-top: 15px;
				}
				&:after{
					content:none;
					margin:0;
				}
			}

			/*cambio boton contacta para mobile*/
			.btn-secondary.btn-xs{
				@media only screen and (max-width: 1270px) {
					position: relative;
					left: 0;
					top: 4px;
					padding: 6px 14px;
				}
				
			}
		}
	}
}


.modal{
	.modal-content{
		.modal-body{
				.form-group{
					position:relative;

					input{
						width: 100%;
						height: 38px;
						border:none;
						background-color: $bg-grey;
						border:solid 1px $bg-grey;
						text-align: left;
					}

					textarea{
						width: 100%;
						height: 38px;
						border:none;
						background-color: $bg-grey;
						border:solid 1px $bg-grey;
						text-align: left;
						height: 215px;
					}
					

					&.has-error{

						input{
							border:solid 1px red;
						}

						textarea{
							border:solid 1px red !important;
						}
					}

				}

				.cajaCheck input[type="checkbox"]:checked,
				.cajaCheck input[type="checkbox"]:not(:checked) {
				  position: absolute;
				  left: -9999px;
				  opacity:0;
				}

				.cajaCheck input[type="checkbox"]:checked + label,
				.cajaCheck input[type="checkbox"]:not(:checked) + label
				{
				  position: relative;
				  padding-left: 28px;
				  cursor: pointer;
				  line-height: 20px;
				  display: inline-block;
				  color: $negro;
				}
				.cajaCheck input[type="checkbox"]:checked + label:before,
				.cajaCheck input[type="checkbox"]:not(:checked) + label:before {
				  content: '';
				  position: absolute;
				  left: 0;
				  top: 0;
				  width: 18px;
				  height: 18px;
				  border: 2px solid $negro;
				}
				.cajaCheck input[type="checkbox"]:checked + label:after,
				.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
				  content: '';
				  width: 18px;
				  height: 18px;
				  background: $negro;
				  position: absolute;
				  top: 0px;
				  left: 0px;
				  -webkit-transition: all 0.2s ease;
				  transition: all 0.2s ease;
				}
				.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
				  opacity: 0;
				  -webkit-transform: scale(0);
				  transform: scale(0);
				}
				.cajaCheck input[type="checkbox"]:checked + label:after {
				  opacity: 1;
				  -webkit-transform: scale(1);
				  transform: scale(1);
				}

				a{
					text-decoration: underline;
					color:$negro;
				}

		}

	}

}


#modal-cookies{
	display: none;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20px;
	z-index: 100;
	background-color: $blanco;
	position: fixed;
	-webkit-box-shadow: 0px -2px 20px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px -2px 20px 0px rgba(0,0,0,0.3);
	box-shadow: 0px -2px 20px 0px rgba(0,0,0,0.3);
	@include media-breakpoint-down(sm) {
		padding: 0 0 20px;
	}
	a{
		text-decoration: underline;
	}
	.btn-secondary.btn-xs{
		@include media-breakpoint-down(sm) {
			position: relative;
			left: 0;
		}
	}
}

#modal-registro{

	.modal-dialog{
		max-width: 1100px;
	}

	.modal-content{
		width: 1100px;
		max-width: 90vw;
		@include media-breakpoint-down(md) {
			max-width: calc(100vw - 15px);
		}
		.modal-header{
			border-bottom: none;
			
			.modal-title{
				// background-color: $negro;

				span{
					// background-color: $blanco;
				}
			}

		}

		.modal-body{

			#alerta-errores-registro{
				display: none;
			}
			label{
				color:$negro;
				&:not(.checkbox){
					padding-left: 12px;
				}
			}
			.cuadro-txt-legal{
				color:$negro;
			}
			h3{
				color:$negro;
			}
		}

	}

}


#modal-validado{

	.modal-dialog{
		max-width: 745px;
	}

	.modal-content{
		width: 745px;
		max-width: 90vw;

		.modal-header{
			border-bottom: none;
		
			.modal-title{

				span{
					background-color: $blanco;
				}
			}

		}

	}

}

#modal-descargabono{

	.modal-dialog{
		max-width: 1100px;
	}

	.modal-content{
		width: 1100px;
		max-width: 90vw;
		// max-height: 90vh;
		// overflow:auto;
		margin-left: auto;
		margin-right: auto;
		
		.modal-header{
			border-bottom: none;
			z-index: 5;
			padding: 50px 115px 0;
			@include media-breakpoint-down(sm) {
				padding: 50px 15px 0;
			}
			.modal-title{

				span{
					background-color: $blanco;
				}
			}
			.close{
				opacity:1;
				span{
					position: absolute;
				    top: 15px;
				    right: 15px;
				    img{
				    	height: 30px;
						width: 30px;
						@include media-breakpoint-down(xs) {
							height: 15px;
							width: 15px;
						}
				    }
				}
			}		
		}

		.modal-body{
			padding: 0px 115px 50px;
			@include media-breakpoint-down(sm) {
				padding: 0px 15px 50px;
			}
			h2{
				color:$negro;
			}
			#alerta-errores-descargabono{
				display: none;
			}

		}

	}

}

#modal-bonook{

	.modal-dialog{
		max-width: 745px;
	}

	.modal-content{
		width: 745px;
		max-width: 90vw;
		@include media-breakpoint-down(md) {
			max-width: calc(100vw - 15px);
		}

		.modal-header{
			border-bottom: none;
		
			.modal-title{

				span{
					background-color: $blanco;
				}
			}

		}

	}

}


body.home{
	background-color: #000ae3!important;
}